import { FC, useMemo, useState } from 'react';
import { TableController, Typography } from '@rubin-dev/goblin';
import {
  AddressByTransaction,
  AddressTransactionsFilterTxType,
  useExplorerIncomingAddressListByTransactionQuery,
  useExplorerOutcomingAddressListByTransactionQuery,
} from '@apolloGenerated';
import {
  Address,
  renderCellUTXO,
  Transaction,
  useSidebarData,
  useSidebarEvents,
} from '@graph-sidebar-module/shared';
import { TransactionDetailContainer } from '@graph-sidebar-module/entities/TransactionDetail';
import { renderCellAmount, renderCellCheckbox, renderCellHash } from '@shared/ui';
import { headersReceive, headersSent, PAGE_SIZE } from '../../const';
import { openWallet } from '@shared/libs';
import { useTranslation } from 'react-i18next';
import cls from './styles.module.scss';
import cx from 'classnames';

export type TransactionDetailContentProps = {
  transaction: Transaction;
};
export const TransactionDetailTable: FC<TransactionDetailContentProps> = ({
  transaction,
}) => {
  const { t } = useTranslation();
  const { hash: currentHash, network, checked, type: tableType } = useSidebarData();
  const { onChangeChecked } = useSidebarEvents();
  const isSentTransaction = transaction.type === AddressTransactionsFilterTxType.Sent;
  const [pageSent, setPageSent] = useState(1);
  const [pageReceived, setPageReceived] = useState(1);
  const {
    data: incomingData,
    previousData: incomingPrevData,
    loading: isLoadingIncoming,
  } = useExplorerIncomingAddressListByTransactionQuery({
    variables: {
      txid: transaction.txid,
      network,
      page: pageSent,
      pageSize: PAGE_SIZE,
    },
  });
  const {
    data: outcomingData,
    previousData: outcomingPrevData,
    loading: isLoadingOutcoming,
  } = useExplorerOutcomingAddressListByTransactionQuery({
    variables: {
      txid: transaction.txid,
      network,
      page: pageReceived,
      pageSize: PAGE_SIZE,
    },
  });
  const isLoading = isLoadingIncoming || isLoadingOutcoming;
  const rowTemplate =
    (type: AddressTransactionsFilterTxType) => (addressItem: AddressByTransaction) => {
      const item = new Address(addressItem, type);
      const isCurrentItem =
        currentHash === item.cluster?.id || currentHash === item.address;
      const isNotUsedAddress =
        item.type === AddressTransactionsFilterTxType.Receives &&
        transaction.type === AddressTransactionsFilterTxType.Receives;
      const isUTXO =
        isCurrentItem &&
        isSentTransaction &&
        item.type === AddressTransactionsFilterTxType.Receives;
      item.updateChecked(currentHash, transaction, checked);

      let checkboxRender: string | JSX.Element;
      if (isUTXO) checkboxRender = renderCellUTXO();
      else if (isCurrentItem)
        checkboxRender = renderCellCheckbox({
          checked: true,
          disabled: true,
        });
      else if (isNotUsedAddress) checkboxRender = ' ';
      else
        checkboxRender = renderCellCheckbox({
          checked: item.checked,
          onChange: (val) =>
            onChangeChecked(
              val,
              item.createTransaction(currentHash, transaction, tableType),
            ),
        });
      return {
        hashUniq: item.address,
        checkbox: checkboxRender,
        hash: (
          <div>
            {renderCellHash({
              hash: item.address,
              type: 'address',
              network,
              longer: true,
              alias: item.address.slice(0, 8) + '...',
            })}
            {item.cluster && (
              <Typography variant="body-12" color="on-surface-secondary-2">
                {t('mention.cluster')}:{' '}
                <Typography
                  variant="body-12"
                  color="text-link"
                  className={cx('cursor-pointer', cls.hash)}
                  onClick={() => openWallet(network, item.cluster!.id)}
                >
                  {item.cluster.owner || item.cluster.id}
                </Typography>
              </Typography>
            )}
          </div>
        ),
        amount: renderCellAmount({ amount: item.amount!, network, type: item.type }),
      };
    };
  const itemsReceived = useMemo(
    () =>
      (
        outcomingData?.explorerOutcomingAddressListByTransaction.edge ||
        outcomingPrevData?.explorerOutcomingAddressListByTransaction.edge ||
        []
      ).map(rowTemplate(AddressTransactionsFilterTxType.Receives)),
    [outcomingData?.explorerOutcomingAddressListByTransaction.edge, rowTemplate],
  );
  const totalReceived =
    outcomingData?.explorerOutcomingAddressListByTransaction.total ||
    outcomingPrevData?.explorerOutcomingAddressListByTransaction.total ||
    0;

  const itemsSent = useMemo(
    () =>
      (
        incomingData?.explorerIncomingAddressListByTransaction.edge ||
        incomingPrevData?.explorerIncomingAddressListByTransaction.edge ||
        []
      ).map(rowTemplate(AddressTransactionsFilterTxType.Sent)),
    [incomingData?.explorerIncomingAddressListByTransaction.edge, rowTemplate],
  );
  const totalSent =
    incomingData?.explorerIncomingAddressListByTransaction.total ||
    incomingPrevData?.explorerIncomingAddressListByTransaction.total;
  return (
    <TransactionDetailContainer
      sendingSlot={
        <TableController
          data={itemsSent}
          headers={headersReceive()}
          total={Number(totalSent || 0)}
          pageSize={PAGE_SIZE}
          onChangePage={setPageSent}
          columnPagination
        />
      }
      receivingSlot={
        <TableController
          data={itemsReceived}
          headers={headersSent()}
          total={Number(totalReceived || 0)}
          pageSize={PAGE_SIZE}
          onChangePage={setPageReceived}
          columnPagination
        />
      }
      isLoading={isLoading}
    />
  );
};
